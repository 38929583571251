import React, { Component } from "react";
import axios from 'axios';
import API_URL from  './../../../ApiUrl';
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import SweetAlert from 'react-bootstrap-sweetalert';

import { DemoFooter } from './../../../components/molecules/';
import ReactRoundedImage from "react-rounded-image";
import "react-datepicker/dist/react-datepicker.css";
import logo from '../../../assets/img/logo-niec-white.png';
import service1 from '../../../assets/img/service-icon/Icon_Service-01.svg';
import service2 from '../../../assets/img/service-icon/Icon_Service-02.svg';
import service3 from '../../../assets/img/service-icon/Icon_Service-03.svg';
import service4 from '../../../assets/img/service-icon/Icon_Service-04.svg';
import service5 from '../../../assets/img/service-icon/Icon_Service-05.svg';
import service6 from '../../../assets/img/service-icon/Icon_Service-06.svg';
import service7 from '../../../assets/img/service-icon/Icon_Service-07.svg';
import service8 from '../../../assets/img/service-icon/Icon_Service-08.svg';
import service9 from '../../../assets/img/service-icon/Icon_Service-09.svg';
import service10 from '../../../assets/img/service-icon/Icon_Service-10.svg';

class GlobalAmbasador extends Component {
  state = {
    nama_lengkap:"",
    alamat:"",
    id_propinsi:"",
    id_kabkota:"",
    no_telp:"",
    email:"",
    kota_lahir:"",
    tgl_lahir:"",
    jenis_kelamin:0,
    code_jenjang:"",
    code_kelas:"",
    code_school:"",
    code_leads_source:"",
    code_konsultan:"",
    code_ambassador:"",
    code_students_category:"",
    message:"Error !",
    error: {},
    showSwal: false,
    showSalError: false,
    formAlert:{display:'none'},
    formAlertClassName:'alert alert-danger',
    inputBorder: "border: 1px solid #ca0b0b",
    btnDisabled: false,
    dataPropinsi: [],
    dataKabkota: [],
    dataGA:'',
    aktifKonten:[],
    checkkodega:"",
    dataJenPen:[],
    dataKelasSemester: [],
    dataKelasSemesterFilter:[],
    dataKabKotaFilter:[],
    renderInputSekolah:"none",
    wa_number: ''
  } 
  
  handleValidation(){
    let errors = {};
    let formIsValid = true;

    //nama
    if(!this.state.nama_lengkap){
      formIsValid = false;
      errors.nama_lengkap = "Nama wajib diisi";
      this.inputNamaLengkap.style = this.state.inputBorder;
    }
    //notelp
    if(!this.state.no_telp){
      formIsValid = false;
      errors.no_telp = "No. Telp wajib diisi";
      this.inputNoTelp.style = this.state.inputBorder;
    }
    //email
    if(!this.state.email){
      formIsValid = false;
      errors.email = "Email wajib diisi";
      this.inputEmail.style = this.state.inputBorder;
    }

    this.setState({error:errors});
    return formIsValid;
  }

  registrationSubmit(e){
    e.preventDefault();
    if(this.handleValidation()){
      this.sendData();
    } else {
      this.setState({
        formAlert:{display:'unset'}, 
        formAlertClassName:'alert alert-warning',
        message:"Error ! Mohon cek kembali isian data"
      })
    }
  }

  sendData(){
    this.setState({
      btnDisabled: true
    });

    let data = this.state;

    var formData = new FormData();
    formData.append('nama_lengkap',data.nama_lengkap);
    formData.append('email',data.email);
    formData.append('no_wa',data.no_telp);
    formData.append('code_ambassador', this.state.dataGA.code_ambassador);

    axios.post(API_URL.superStudent+'/ambassador/register-student',formData,{
      headers : { 'Authorization': `Bearer ${API_URL.ambassadorKey}` }
    })
    .then(response => {
      if (response.status == '200') {
        this.setState({
          showSwal: true,
        })
      } else {
        this.setState({
          showSalError: true,
          message: response.data.message
        })
      }
    })
  }

  onBlurNamaLengkap(e){
    e.preventDefault()
    if(this.state.nama_lengkap.length > 2){
      this.inputNamaLengkap.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);  
        error.nama_lengkap = '';                                 
        return { error };                   
      })
    }else{
      this.inputNamaLengkap.style = this.state.inputBorder;
      this.setState(prevState => {
        let errors = Object.assign({}, prevState.error);  
        errors.nama_lengkap = 'Nama Lengkap Tidak boleh kosong';                                 
        this.setState({error:errors});                  
      })
    }
  }

  onBlurNoTelp(e){
    e.preventDefault()
    if(this.state.no_telp.length > 0){
        this.inputNoTelp.style = "";
        this.setState(prevState => {
          let error = Object.assign({}, prevState.error);  
          error.no_telp = '';                                 
          return { error };                   
        })
    }else{
      this.inputNoTelp.style = this.state.inputBorder;
      this.setState(prevState => {
        let errors = Object.assign({}, prevState.error);  
        errors.no_telp = 'No. Telp Tidak boleh kosong';                                 
        this.setState({error:errors});                  
      })
    }
  }

  onBlurEmail(e){
    e.preventDefault()
    if(this.state.email.length > 5){
      this.inputEmail.style = "";
      this.setState(prevState => {
        let error = Object.assign({}, prevState.error);  
        error.email = '';                                 
        return { error };                   
      })
    }else{
      this.inputEmail.style = this.state.inputBorder;
      this.setState(prevState => {
        let errors = Object.assign({}, prevState.error);  
        errors.email = 'Email Tidak boleh kosong';                                 
        this.setState({error:errors});                  
      })
    }
  }
  
  componentDidMount = () => { 
    this.getProfileAmbassador();
  }

  getProfileAmbassador() {
    var formData = new FormData();
    formData.append('username',this.props.match.params.username);
    axios.post(API_URL.superStudent+'/ambassador/getAmbassador',formData,{
      headers : { 'Authorization': `Bearer ${API_URL.ambassadorKey}` }
    })
    .then(response => response.data.data)
    .then(result => this.setState({ dataGA: result.userable, wa_number: 'https://wa.me/62'+result.userable.phone.slice(1) }))
    .catch(error => console.log('error'))
  }

  clearInput() {
    this.inputNamaLengkap.value = "";
    this.inputNoTelp.value = "";
    this.inputEmail.value = "";
  }

  render() {
    return (
      <>
        <div>
          <div className="bg-header">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <img className="img-logo mt-5" src={logo} />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="text-judul mt-5">{this.state.dataGA.name}</h2>
                  <p className="description mt-2 mb-5" style={{ fontWeight: '500', color:'white' }}>{this.state.dataGA.about}</p>
                </div>
                <div className="col-lg-6" style={{ backgroundImage: "url(" + this.state.dataGA.foto + ")", backgroundSize: 'cover', maxWidth: '36%', borderRadius: '200px', opacity: '0.5' }}>
                  <h1 className="text-bayang p-5 mt-5 mb-5 pull-left">{this.state.dataGA.name}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-about">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 d-flex justify-content-center" style={{marginTop: '-140px'}}>
                  <ReactRoundedImage
                    image={this.state.dataGA.foto}
                    roundedColor="#321124"
                    imageWidth="300"
                    imageHeight="300"
                    roundedSize="15"
                    borderRadius="150"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex justify-content-center">
                  <h2 className="text-judul mt-5">{this.state.dataGA.name}</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex justify-content-center">
                  <a href={this.state.wa_number}><Button className="text-center mt-3 mb-5" style={{ backgroundColor:'white', borderRadius:'15px', borderStyle:'none', color: 'black' }}>Contact Me</Button></a>
                </div>
              </div>
            </div>
          </div>
          <div className="section" style={{backgroundColor:'#f2f2f2'}} >
            <Container>
              <Row>
                <Col lg="12" md="12">
                  <h2 className="mb-5" style={{ color: 'black', fontWeight:'700', padding:'0px', margin:'0px', textAlign:"center" }}>NIEC SERVICE</h2><br />
                  <p className="description" style={{ fontWeight: '600', textAlign:"center" }}>
                    Dengan pengalaman 12 tahun,kami telah memberangkatkan lebih dari 1000 anak Indonesia kuliah di
                    luar negeri. Bekerja sama dengan lebih dari 150 kampus secara langsung kedestinasi puluhan negara
                  </p>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg="6" md="6" className="d-flex justify-content-center">
                  <img className="img-service" src={service1} />
                </Col>
                <Col lg="6" md="6" className="d-flex justify-content-center">
                  <img className="img-service" src={service2} />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg="6" md="6" className="d-flex justify-content-center">
                  <img className="img-service" src={service3} />
                </Col>
                <Col lg="6" md="6" className="d-flex justify-content-center">
                  <img className="img-service" src={service4} />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg="6" md="6" className="d-flex justify-content-center">
                  <img className="img-service" src={service5} />
                </Col>
                <Col lg="6" md="6" className="d-flex justify-content-center">
                  <img className="img-service" src={service6} />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg="6" md="6" className="d-flex justify-content-center">
                  <img className="img-service" src={service7} />
                </Col>
                <Col lg="6" md="6" className="d-flex justify-content-center">
                  <img className="img-service" src={service8} />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg="6" md="6" className="d-flex justify-content-center">
                  <img className="img-service" src={service9} />
                </Col>
                <Col lg="6" md="6" className="d-flex justify-content-center">
                  <img className="img-service" src={service10} />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="section" style={{backgroundColor:'#f2f2f2'}}>
            <Container>
              <h2 className="mb-5" style={{ color: 'black', fontWeight:'700', padding:'0px', margin:'0px', textAlign:"center" }}>REGISTER</h2>
              <form>
                <Row>
                  <Col className="ml-auto mr-auto" md="6">
                    <FormGroup>
                      <Label for="nama_lengkap" style={{ color: 'black', fontWeight:'500'}}>Nama Lengkap</Label>
                      <input className="form-control" type="nama_lengkap"  id="nama_lengkap"
                        onChange={(e) => this.setState({nama_lengkap:e.target.value})}
                        onBlur={(e) => this.onBlurNamaLengkap(e)}
                        ref={e => this.inputNamaLengkap = e}
                      />
                      <label className="error-label-register">
                        {this.state.error.nama_lengkap}
                      </label>
                    </FormGroup>
                    <FormGroup>
                      <Label for="inputEmail4" style={{ color: 'black', fontWeight:'500'}}>Email</Label>
                      <input className="form-control" type="email"  id="inputEmail4"
                        onChange={(e) => this.setState({email:e.target.value})}
                        onBlur={(e) => this.onBlurEmail(e)}
                        ref={e => this.inputEmail = e}
                      />
                      <label className="error-label-register">
                          {this.state.error.email}
                      </label>
                    </FormGroup>
                    <FormGroup>
                      <Label for="no_telp" style={{ color: 'black', fontWeight:'500'}}>No HP/WA</Label>
                      <input className="form-control" type="number"  id="no_telp"
                        onChange={(e) => this.setState({no_telp:e.target.value})}
                        onBlur={(e) => this.onBlurNoTelp(e)}
                        ref={e => this.inputNoTelp = e}
                      />
                      <label className="error-label-register">
                          {this.state.error.no_telp}
                      </label>
                    </FormGroup>

                    <Row>
                      <Col md="12" style={{padding:10}}>
                        <div id="message" className={this.state.formAlertClassName+" alert-dismissible"} style={this.state.formAlert}>
                          {this.state.message}
                        </div>
                      </Col>
                      <Col md="12" className="d-flex justify-content-center">
                        <Button disabled={this.state.btnDisabled} className="text-center" type="submit"  onClick={e=>this.registrationSubmit(e)} style={{ backgroundColor:'#535353', borderRadius:'15px', borderStyle:'none', color: 'white', minWidth: '400px' }}>Kirim</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <SweetAlert
                  danger
                  show={this.state.showSalError}
                  title="Register Failed"
                  onConfirm={() => {
                    this.setState({
                      showSalError: false,
                      btnDisabled: true
                    }, () => {
                      window.location.href="/"
                    })
                  }}
                >
                  {this.state.message}
                </SweetAlert>

                <SweetAlert
                  show={this.state.showSwal}
                  style={{ backgroundColor:'black',
                  color: 'white' }}
                  closeOnCancel= 'true'
                  title="Pendaftaran Berhasil!"
                  confirmBtnText="OK"
                  onConfirm={() => 
                  window.location.href="/"}
                >
                 Selamat!!, pendaftaran anda sudah berhasil, 
                </SweetAlert>
                
              </form>
            </Container>
          </div>

        </div>
        <DemoFooter />
      </>
    );
  }
}

export default GlobalAmbasador;
 